<template>
  <div class="container flex mx-auto justify-center object-center">
	<!-- <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 place-items-center"> -->
  <div class="flex flex-col flex-wrap md:flex-col lg:flex-row gap-3 items-center lg:justify-center lg:items-stretch object-center">
    <PostCard class="mt-6"
      v-for="post in data.posts.nodes"
      :key="post.slug"
      :slug="post.slug"
      :title="post.title"
      :excerpt="post.excerpt"
      :image="post.image">
    </PostCard>
	<!-- <p class="text-xl">There are {{ data }} ships.</p> -->
  </div></div>
  </template>
  
  <script lang="ts" setup>
  type PostResults = {
    posts:{
      nodes:{
        title: string,
        image: string,
        excerpt: string,
        slug: string
      }[]
    }
  }

  const query = gql`
	query Posts {
  posts {
    nodes {
      title 
      date
      slug
      excerpt
    }
  }
}
  `
  
  const { data } = await useAsyncQuery<PostResults>(query)
  </script>
  